module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.7.0_gatsby@5.7.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/forest-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a5fa00f52ef2e7f80c7a3041ce4552d"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.7.0_hgefj54jzwya6t5wz7qwyqdbja/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.7.0_ygqkwb4gg3aean7xjfdauovyqq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
